import React from 'react';
import ScrollToTop from '../components/ScrollToTop';
import SignIn from '../components/Signin';
import {
    Container,
    Form,
    FormButton,
    FormContent,
    FormH1,
    FormInput,
    FormLabel, FormText,
    FormWrap,
    Icon,
    Text,
} from '../components/Signin/SigninElements';
import styled from "styled-components";

export const ContainerX = styled.div`
    min-height: 692px;
    position: fixed;
    bottom: 0;
    left: 0;
    right:0;
    top: 0;
    z-index: 0;
    overflow: auto;
    background: black;
`;

const ImpressumPage = () => {
    return (
        <>
            <ContainerX>
                <FormWrap>
                    <Icon to="/">Impressum</Icon>
                    <FormContent>
                        <Form action="#">
                            <FormH1>Verantwortlich:</FormH1>
                            <FormText>Sültrup Industrievertrieb</FormText>
                            <FormText>Inh. Norbert Sommerfeld</FormText>
                            <FormText>Carl-Benz-Str. 11</FormText>
                            <FormText>28816 Stuhr</FormText>
                            <FormText></FormText>
                            <FormText></FormText>
                            <FormText></FormText>
                            <FormH1>Kontakt:</FormH1>
                            <FormText>Telefon: +49 0421 8718091</FormText>
                            <FormText>Telefax: +49 0421 8718092</FormText>
                            <FormText>E-Mail: info@sueltrup-technik.de</FormText>
                            <FormText></FormText>
                            <FormText></FormText>
                            <FormText></FormText>
                            <FormText>Umsatzsteuer-Identifikationsnummer:
                                DE222481583</FormText>
                            <FormText></FormText>
                            <FormH1>Haftung für Inhalte und Links:</FormH1>
                            <FormText>
                                Die Inhalte wurden mit größter Sorgfalt erstellt. Für die Richtigkeit und Vollständigkeit der Inhalte kann jedoch keine Haftung übernommen werden.

                                Die evtl. aufgeführten Links führen zu Inhalten fremder Anbieter. Für diese Inhalte ist der jeweilige Anbieter verantwortlich. Bei Bekanntwerden von Rechtsverletzungen werden diese Links entfernt.</FormText>
                        <FormText></FormText>
                            <FormH1>Datenschutzerklärung:</FormH1>
                            <div style={{ overflowY: 'scroll', maxHeight: '400px' }}>
                            <FormText>
                                1. Datenschutz auf einen Blick
                                Allgemeine Hinweise
                                Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten
                                passiert, wenn Sie unsere Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie
                                persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen
                                Sie unserer unter diesem Text aufgeführten Datenschutzerklärung.
                                Datenerfassung auf unserer Website
                                Wer ist verantwortlich für die Datenerfassung auf dieser Website?
                                Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten
                                können Sie dem Impressum dieser Website entnehmen.
                                Wie erfassen wir Ihre Daten?
                                Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z.B. um
                                Daten handeln, die Sie in ein Kontaktformular eingeben.
                                Andere Daten werden automatisch beim Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor
                                allem technische Daten (z.B. Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die
                                Erfassung dieser Daten erfolgt automatisch, sobald Sie unsere Website betreten.
                                Wofür nutzen wir Ihre Daten?
                                Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gewährleisten. Andere
                                Daten können zur Analyse Ihres Nutzerverhaltens verwendet werden.
                                Welche Rechte haben Sie bezüglich Ihrer Daten?
                                Sie haben jederzeit das Recht unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer
                                gespeicherten personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht, die Berichtigung,
                                Sperrung oder Löschung dieser Daten zu verlangen. Hierzu sowie zu weiteren Fragen zum Thema
                                Datenschutz können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden. Des
                                Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
                                Außerdem haben Sie das Recht, unter bestimmten Umständen die Einschränkung der Verarbeitung Ihrer
                                personenbezogenen Daten zu verlangen. Details hierzu entnehmen Sie der Datenschutzerklärung unter
                                „Recht auf Einschränkung der Verarbeitung“.
                                2. Allgemeine Hinweise und Pflichtinformationen
                                Datenschutz
                                Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre
                                personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie
                                dieser Datenschutzerklärung.
                                Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben.
                                Personenbezogene Daten sind Daten, mit denen Sie persönlich identifiziert werden können. Die vorliegende
                                Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie erläutert auch, wie
                                und zu welchem Zweck das geschieht.
                                Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail)
                                Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
                                möglich.
                                Hinweis zur verantwortlichen Stelle
                                Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:
                                Sültrup-Industrievertrieb, Inh. Norbert Sommerfeld
                                Carl-Benz-Str. 11
                                28816 Stuhr
                                Telefon: +49 (0) 421-8718091
                                E-Mail: info@sueltrup-technik.de
                                Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam mit anderen über
                                die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.B. Namen, E-Mail-Adressen o. Ä.)
                                entscheidet.
                                Widerruf Ihrer Einwilligung zur Datenverarbeitung
                                Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können eine
                                bereits erteilte Einwilligung jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an uns.
                                Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
                                Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie gegen
                                Direktwerbung (Art. 21 DSGVO)
                                Wenn die Datenverarbeitung auf Grundlage von Art. 6 Abs. 1 lit. e oder f DSGVO erfolgt, haben Sie
                                jederzeit das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, gegen die Verarbeitung
                                Ihrer personenbezogenen Daten Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmungen
                                gestütztes Profiling. Die jeweilige Rechtsgrundlage, auf denen eine Verarbeitung beruht, entnehmen Sie
                                dieser Datenschutzerklärung. Wenn Sie Widerspruch einlegen, werden wir Ihre betroffenen
                                personenbezogenen Daten nicht mehr verarbeiten, es sei denn, wir können zwingende schutzwürdige
                                Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen oder die
                                Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
                                (Widerspruch nach Art. 21 Abs. 1 DSGVO).
                                Werden Ihre personenbezogenen Daten verarbeitet, um Direktwerbung zu betreiben, so haben Sie das
                                Recht, jederzeit Widerspruch gegen die Verarbeitung Sie betreffender personenbezogener Daten zum
                                Zwecke derartiger Werbung einzulegen; dies gilt auch für das Profiling, soweit es mit solcher
                                Direktwerbung in Verbindung steht. Wenn Sie widersprechen, werden Ihre personenbezogenen Daten
                                anschließend nicht mehr zum Zwecke der Direktwerbung verwendet (Widerspruch nach Art. 21 Abs. 2
                                DSGVO).
                                Beschwerderecht bei der zuständigen Aufsichtsbehörde
                                Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein Beschwerderecht bei einer
                                Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes
                                oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht besteht unbeschadet anderweitiger
                                verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.
                                Recht auf Datenübertragbarkeit
                                Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags
                                automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen, maschinenlesbaren Format
                                aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen
                                verlangen, erfolgt dies nur, soweit es technisch machbar ist.
                                Auskunft, Sperrung, Löschung und Berichtigung
                                Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche
                                Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den
                                Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten.
                                Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit unter der
                                im Impressum angegebenen Adresse an uns wenden.
                                Recht auf Einschränkung der Verarbeitung
                                Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
                                Hierzu können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden. Das Recht
                                auf Einschränkung der Verarbeitung besteht in folgenden Fällen:
                                Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten, benötigen wir
                                in der Regel Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie das Recht, die
                                Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
                                Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig geschah / geschieht, können Sie
                                statt der Löschung die Einschränkung der Datenverarbeitung verlangen.
                                Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie jedoch zur Ausübung,
                                Verteidigung oder Geltendmachung von Rechtsansprüchen benötigen, haben Sie das Recht, statt der
                                Löschung die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
                                Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abwägung zwischen
                                Ihren und unseren Interessen vorgenommen werden. Solange noch nicht feststeht, wessen Interessen
                                überwiegen, haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten
                                zu verlangen.
                                Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt haben, dürfen diese Daten – von
                                ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder
                                Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder
                                juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses der Europäischen Union oder
                                eines Mitgliedstaats verarbeitet werden.
                                3. Datenerfassung auf unserer Website
                                Anfrage per E-Mail, Telefon oder Telefax
                                Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre Anfrage inklusive aller daraus
                                hervorgehenden personenbezogenen Daten (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens
                                bei uns gespeichert und verarbeitet. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.
                                Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit
                                der Erfüllung eines Vertrags zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
                                erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a
                                DSGVO) und / oder auf unseren berechtigten Interessen (Art. 6 Abs. 1 lit. f DSGVO), da wir ein berechtigtes
                                Interesse an der effektiven Bearbeitung der an uns gerichteten Anfragen haben.
                                Die von Ihnen an uns per Kontaktanfragen übersandten Daten verbleiben bei uns, bis Sie uns zur Löschung
                                auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt
                                (z. B. nach abgeschlossener Bearbeitung Ihres Anliegens). Zwingende gesetzliche Bestimmungen –
                                insbesondere gesetzliche Aufbewahrungsfristen – bleiben unberührt.
                            </FormText></div>
                        </Form>
                    </FormContent>
                </FormWrap>
            </ContainerX>
        </>
    );
};

export default ImpressumPage;
