import React from 'react';
import './App.css';
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom';
import Home from './pages'
import SigninPage from './pages/signin'
import ImpressumPage from "./pages/impressum";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/signin" component={SigninPage} exact />
        <Route path="/impressum" component={ImpressumPage} exact />
          <Route
              render={({ history }) => {
                  console.log('Redirecting to Home');
                  history.push('/');
                  return null;
              }}
          />
      </Switch>
    </Router>
  );
}

export default App;