export const homeObjOne = {
    id: 'about',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Kosten reduzieren mit unserem',
    headline: 'C-Teile-Management',
    description: 'Optimieren Sie Ihre Beschaffungsprozesse und steigern Sie die Effizienz mit unserem umfassenden C-Teile-Management. Wir verstehen, dass effektives C-Teile-Management mehr bedeutet als nur die Lieferung von Produkten. Es geht darum, Ihre Betriebsabläufe zu optimieren, Lagerbestände zu minimieren und Kosteneinsparungen zu realisieren. Mit unserer Expertise bieten wir maßgeschneiderte Lösungen, die auf Ihre individuellen Bedürfnisse zugeschnitten sind. Verlassen Sie sich darauf, dass wir Ihr C-Teile-Management effizient gestalten, damit Sie sich auf das konzentrieren können, was wirklich zählt – Ihr Kerngeschäft.',
    buttonLabel: 'Nehmen Sie Kontakt auf',
    imgStart: false,
    img: require('../../images/undraw_container_ship_re_alm4.svg').default,
    alt: 'Rocket',
    dark: true,
    primary: true,
    darkText: false,
};

export const homeObjTwo = {
    id: 'discover',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Das sind wir',
    headline: 'Sültrup Industrievertrieb',
    description: 'Sültrup Industrievertrieb steht für mehr als nur Produkte – wir sind Ihr engagierter Partner für herausragende industrielle Lösungen. Unsere Werte umfassen Qualität, Zuverlässigkeit und Innovation. Mit einem erfahrenen Team, das auf jahrelanger Expertise basiert, bieten wir maßgeschneiderte Dienstleistungen, die auf Ihre spezifischen Anforderungen zugeschnitten sind. Von Elektromotoren bis hin zu intelligenten Steuerungssystemen sind wir stolz darauf, Ihr Vertrauen durch erstklassigen Service und erstklassige Produkte zu verdienen. Erfahren Sie mehr darüber, wer wir sind und wie wir Ihr Unternehmen mit unserem Engagement für Spitzenleistungen voranbringen können.',
    buttonLabel: "",
    imgStart: true,
    img: require('../../images/undraw_connecting_teams_re_hno7.svg').default,
    alt: 'Planet',
    dark: false,
    primary: false,
    darkText: true,
};
