import React from 'react';
import { useHistory } from 'react-router-dom';
import {animateScroll as scroll } from 'react-scroll';
import { FaFacebook, FaInstagram, FaYoutube, FaTwitter, FaLinkedin } from 'react-icons/fa';
import { FooterContainer, 
        FooterWrap, 
        FooterLinksContainer, 
        FooterLinksWrapper, 
        FooterLinkItems, 
        FooterLinkTitle,
        SocialMedia,
        SocialMediaWrap,
        SocialLogo,
        WebsiteRights,
        SocialIcons,
        SocialIconLink,
    		} 
        from './FooterElements';

const Footer = () => {
    const history = useHistory();
    //function that scrolls to top of page
    const toggleHome = () => {
        scroll.scrollToTop();
    };

    const handleImpressumClick = () => {
        // Navigate to "/impressum" when the element is clicked
        history.push('/impressum');
    };

    return (
        <FooterContainer id='footer'>
            <FooterWrap>{/*
                <FooterLinksContainer>
                    <FooterLinksWrapper>
                        <FooterLinkItems>
                            <FooterLinkTitle>Services</FooterLinkTitle>
                        </FooterLinkItems>
                        <FooterLinkItems>
                            <FooterLinkTitle>Company</FooterLinkTitle>
                        </FooterLinkItems>
                    </FooterLinksWrapper>
                    <FooterLinksWrapper>
                        <FooterLinkItems>
                        <FooterLinkTitle>Projects</FooterLinkTitle>
                        </FooterLinkItems>
                        <FooterLinkItems>
                        <FooterLinkTitle>Contact</FooterLinkTitle>
                        </FooterLinkItems>
                    </FooterLinksWrapper>
                </FooterLinksContainer>*/}
                <SocialMedia>
                    <SocialMediaWrap>
                        <SocialLogo to="/" onClick={toggleHome}>
                            Sültrup Industrievertrieb
                        </SocialLogo>
                        <WebsiteRights>© {new Date().getFullYear()} Alle Rechte vorbehalten</WebsiteRights>
                        <WebsiteRights>E-Mail: info@sueltrup-technik.de</WebsiteRights>
                        <WebsiteRights style={{ cursor: 'pointer' }} onClick={handleImpressumClick}>Impressum</WebsiteRights>

                        {/*
                        <SocialIcons>
                            <SocialIconLink href="//www.twitter.com/" target="_blank" aria-label="Facebook">
                                <div>Test</div>
                            </SocialIconLink>
                            <SocialIconLink href="/" target="_blank" aria-label="Instagram">
                                <FaInstagram />
                            </SocialIconLink>
                            <SocialIconLink href="/" target="_blank" aria-label="YouTube">
                                <FaYoutube />
                            </SocialIconLink>
                            <SocialIconLink href="/" target="_blank" aria-label="Twitter">
                                <FaTwitter />
                            </SocialIconLink>
                            <SocialIconLink href="/" target="_blank" aria-label="LinkedIn">
                                <FaLinkedin />
                            </SocialIconLink>
                        </SocialIcons>*/}
                    </SocialMediaWrap>
                </SocialMedia>

            </FooterWrap>
            
        </FooterContainer>
    )
}

export default Footer
