import React from 'react';
import Icon1 from '../../images/motor.png';
import Icon2 from '../../images/druckschalter.png';
import Icon3 from '../../images/stork.png';
import Icon4 from '../../images/kondensatoren.png';
import Icon5 from '../../images/streetsweeper.png';
import Icon6 from '../../images/environmentaltruck.png';
import { ServicesContainer,
        ServicesH1,
        ServicesWrapper,
        ServicesCard,
        ServicesIcon,
        ServicesH2,
        ServicesP 
        } from './ServicesElements';

const Services = () => {
    return (
        <ServicesContainer id="services">
            <ServicesH1>Unsere Leistungen</ServicesH1>
            <ServicesWrapper>
                <ServicesCard>
                    <ServicesIcon src={Icon1} />
                    <ServicesH2>Elektromotoren</ServicesH2>
                    <ServicesP>Von standardisierten Lösungen bis hin zu maßgeschneiderten Antrieben bieten wir ein breites Spektrum an Elektromotoren für industrielle Anwendungen. </ServicesP>
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={Icon2} />
                    <ServicesH2>Druckschalter</ServicesH2>
                    <ServicesP>Optimieren Sie die Steuerung und Überwachung von Drucksystemen mit unseren Condor-Druckschaltern. Finden Sie die ideale Lösung für präzise Drucksteuerung.</ServicesP>
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={Icon3} />
                    <ServicesH2>Störk-Tronic Produkte</ServicesH2>
                    <ServicesP>Unsere Partnerschaft ermöglicht Ihnen den Zugang zu führenden Technologien, sei es für kundenspezifische Anforderungen oder bewährte Standardlösungen.</ServicesP>
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={Icon4} />
                    <ServicesH2>Kondensatoren</ServicesH2>
                    <ServicesP>Entdecken Sie hochwertige Kondensatoren für unterschiedliche Anwendungen. Unser Sortiment bietet verschiedene Leistungsklassen für Ihre Zwischenkreisanwendung.</ServicesP>
                </ServicesCard>
            </ServicesWrapper>
        </ServicesContainer>
    )
}

export default Services
